/* eslint-disable no-nested-ternary */
const prod = process.env.NODE_ENV === 'production';
// Hide console logs in production mode
if (prod) {
  console.log = () => {};
}
export default {
  siteName: 'React',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} test`,

  apiUrl: 'https://aws-lb.happykamper.io/',
  csvUrl: 'https://aws-lb.happykamper.io/ExmpleCSV',
  sailsUrl: 'https://aws-lb.happykamper.io/',

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  tinyEditorKey: 'e3tq72z0kgbpfay3800n06hqbu1su39v084nye4weku75ib6',
  dashboard: '/dashboard',
};
